
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Tabs, { ITab } from '@/components/tabs.vue'
import { iconUrlForTheme } from '@/helpers/theme'
import { ModelModule } from '@/modules/model/store'

import ObjectFamilyMenu from '../objects/object-family-menu.vue'

@Component({
  components: {
    ObjectFamilyMenu,
    Tabs
  },
  name: 'ModelObjectDependenciesListHeader'
})
export default class extends Vue {
  modelModule = getModule(ModelModule, this.$store)

  @Prop() readonly objectId!: string
  @Prop() readonly connectionId!: string
  @Prop() readonly originId!: string
  @Prop() readonly targetId!: string
  @Prop() readonly directConnectionCount!: number
  @Prop() readonly lowerConnectionCount!: number

  get tabs () {
    const tabs: ITab[] = [
      {
        id: 'direct',
        text: `${this.directConnectionCount} direct`,
        to: {
          query: this.$setQuery({
            expanded_connection_tab: 'direct'
          })
        }
      },
      {
        id: 'lower',
        text: `${this.lowerConnectionCount} lower`,
        to: {
          query: this.$setQuery({
            expanded_connection_tab: 'lower'
          })
        }
      }
    ]
    return tabs
  }

  get origin () {
    return this.modelModule.objects[this.originId]
  }

  get expandedConnection () {
    return this.$queryValue('expanded_connection')
  }

  get originIcon (): string | undefined {
    if (!this.origin.icon) return
    return iconUrlForTheme(this.origin.icon)
  }

  get originName () {
    return this.origin.name || `${this.origin.type.slice(0, 1).toUpperCase()}${this.origin.type.slice(1)}`
  }

  get target () {
    return this.modelModule.objects[this.targetId]
  }

  get targetName () {
    return this.target.name || `${this.target.type.slice(0, 1).toUpperCase()}${this.target.type.slice(1)}`
  }

  get targetIcon (): string | undefined {
    if (!this.target.icon) return
    return iconUrlForTheme(this.target.icon)
  }

  selectTab (tab: string) {
    this.$replaceQuery({
      expanded_connection: this.connectionId,
      expanded_connection_tab: tab
    })
  }
}
