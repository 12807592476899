
import { layoutAreaObjects, objectConnectionPoints } from '@icepanel/app-graphics'
import { DiagramContentPartial, ModelObject, PermissionType, Task, TaskModelObjectUpdate } from '@icepanel/platform-api-client'
import Fuse from 'fuse.js'
import debounce from 'lodash/debounce'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { iconUrlForTheme } from '@/helpers/theme'
import { DiagramModule } from '@/modules/diagram/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'
import { objectIcons } from '../../helpers/objects'
import ActionsMenu from '../actions-menu.vue'
import ObjectSelectMenuList from '../object-select-menu/list.vue'

interface IGroup extends ModelObject {
  assignedEvery: boolean
  assignedSome: boolean
}

@Component({
  components: {
    ActionsMenu,
    ObjectSelectMenuList
  },
  name: 'ModelObjectGroupsMenu'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly model!: ModelObject | ModelObject[]
  @Prop() readonly permission!: PermissionType

  @Ref() readonly searchRef?: HTMLElement

  iconUrlForTheme = iconUrlForTheme

  search = ''
  searchModel = ''
  searchFocused = false

  addGroup = false
  groupIdsVisible: string[] = []

  objectIcons = objectIcons

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get currentDiagramContent () {
    return Object.values(this.diagramModule.diagramContents).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get objects () {
    const objects = this.model instanceof Array ? this.model : this.model ? [this.model] : []
    return objects.filter(o => o.type !== 'group')
  }

  get objectsOnlyGroups () {
    return this.objects.every(o => o.type === 'group')
  }

  get groups () {
    return Object
      .values(this.modelModule.objects)
      .filter(o => o.type === 'group')
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(g => {
        const assignedEvery = !!this.objects.length && this.objects.every(o => o.groupIds.includes(g.id))
        const assignedSome = !!this.objects.length && !assignedEvery && this.objects.some(o => o.groupIds.includes(g.id))
        return {
          ...g,
          assignedEvery,
          assignedSome
        }
      })
  }

  get visibleGroups (): IGroup[] {
    return this.groups.filter(o => this.groupIdsVisible.includes(o.id))
  }

  get groupsByKey () {
    return Object
      .values(this.groups)
      .reduce((p, c) => ({
        ...p,
        [c.id]: c
      }), {} as Record<string, IGroup>)
  }

  get groupsAssigned () {
    return this.groups.filter(o => o.assignedEvery || o.assignedSome)
  }

  get multiple () {
    return this.groups.some(o => o.assignedSome)
  }

  get filteredGroupsFuzzy () {
    return new Fuse(this.visibleGroups, {
      keys: ['name'],
      threshold: 0.3
    })
  }

  get filteredGroups () {
    return this.search ? this.filteredGroupsFuzzy.search(this.search).map(o => o.item) : this.visibleGroups
  }

  setSearchDebounce = debounce(this.setSearch.bind(this), 300)

  setSearch (term: string) {
    this.search = term
  }

  opened () {
    this.addGroup = false

    this.setVisibleGroupIds()

    analytics.modelObjectGroupsMenu.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  setVisibleGroupIds () {
    this.groupIdsVisible = [...new Set(this.objects.flatMap(o => o.groupIds))]
  }

  updateDiagramContent (modelObjects: Record<string, ModelObject>) {
    const diagramProps: DiagramContentPartial = {}
    const revertDiagramProps: DiagramContentPartial = {}

    const currentDiagramContent = this.currentDiagramContent
    if (currentDiagramContent) {
      const objects = { ...currentDiagramContent.objects }

      Object
        .entries(layoutAreaObjects(objects, modelObjects))
        .forEach(([id, rect]) => {
          revertDiagramProps.objects = {
            ...revertDiagramProps.objects,
            $update: {
              ...revertDiagramProps.objects?.$update,
              [id]: {
                ...revertDiagramProps.objects?.$update?.[id],
                height: objects[id].height,
                width: objects[id].width,
                x: objects[id].x,
                y: objects[id].y
              }
            }
          }
          objects[id] = {
            ...objects[id],
            ...rect
          }
          diagramProps.objects = {
            ...diagramProps.objects,
            $update: {
              ...diagramProps.objects?.$update,
              [id]: {
                ...diagramProps.objects?.$update?.[id],
                ...rect
              }
            }
          }
        })

      Object
        .entries(objectConnectionPoints(Object.values(objects), Object.values(currentDiagramContent.connections)))
        .forEach(([id, points]) => {
          revertDiagramProps.connections = {
            ...revertDiagramProps.connections,
            $update: {
              ...revertDiagramProps.connections?.$update,
              [id]: {
                ...revertDiagramProps.connections?.$update?.[id],
                points: currentDiagramContent.connections[id].points
              }
            }
          }
          diagramProps.connections = {
            ...diagramProps.connections,
            $update: {
              ...diagramProps.connections?.$update,
              [id]: {
                ...diagramProps.connections?.$update?.[id],
                points
              }
            }
          }
        })
    }

    return {
      diagramProps,
      revertDiagramProps
    }
  }

  toggleGroup (groupId: string) {
    const tasks: Task[] = []
    const revertTasks: Task[] = []

    const func: (() => Promise<any>)[] = []

    const updateValue = !this.groupsByKey[groupId]?.assignedEvery

    const modelObjects = { ...this.modelModule.objects }

    if (this.currentDiagram?.status === 'draft') {
      const groupUpdates: TaskModelObjectUpdate[] = []

      this.objects.forEach(o => {
        if (updateValue && !o.groupIds.includes(groupId)) {
          groupUpdates.push({
            id: o.id,
            props: {
              groupIds: [...o.groupIds, groupId]
            },
            type: 'model-object-update'
          })
          modelObjects[o.id].groupIds = [...o.groupIds, groupId]
        } else if (!updateValue && o.groupIds.includes(groupId)) {
          groupUpdates.push({
            id: o.id,
            props: {
              groupIds: o.groupIds.filter(o => o !== groupId)
            },
            type: 'model-object-update'
          })
          modelObjects[o.id].groupIds = o.groupIds.filter(o => o !== groupId)
        }
      })

      const {
        diagramProps,
        revertDiagramProps
      } = this.updateDiagramContent(modelObjects)

      revertTasks.push({
        id: this.currentDiagram.id,
        props: {
          ...revertDiagramProps,
          tasksProposed: {
            $append: groupUpdates.map(o => ({
              id: o.id,
              props: {
                groupIds: this.modelModule.objects[o.id].groupIds
              },
              type: o.type
            }))
          }
        },
        type: 'diagram-content-update'
      })

      const { diagramContent, diagramContentUpdate } = this.diagramModule.generateDiagramContentCommit(this.currentDiagram.id, {
        ...diagramProps,
        tasksProposed: {
          $append: groupUpdates
        }
      })
      this.diagramModule.setDiagramContentVersion(diagramContent)

      func.push(() => this.diagramModule.diagramContentUpdate({
        diagramId: diagramContent.id,
        landscapeId: this.currentLandscape.id,
        props: diagramContentUpdate,
        versionId: this.currentVersion.id
      }))

      tasks.push({
        id: diagramContent.id,
        props: diagramContentUpdate,
        type: 'diagram-content-update'
      })
    } else {
      this.objects.forEach(o => {
        let groupIds = [...o.groupIds]

        if (updateValue && !groupIds.includes(groupId)) {
          groupIds.push(groupId)
          modelObjects[o.id].groupIds.push(groupId)
        } else if (!updateValue && groupIds.includes(groupId)) {
          groupIds = groupIds.filter(o => o !== groupId)
          modelObjects[o.id].groupIds = groupIds.filter(o => o !== groupId)
        }

        revertTasks.push({
          id: o.id,
          props: {
            groupIds: o.groupIds
          },
          type: 'model-object-update'
        })

        const { object, objectUpdate } = this.modelModule.generateObjectCommit(o.id, {
          groupIds
        })
        this.modelModule.setObjectVersion(object)

        func.push(() => this.modelModule.objectUpdate({
          landscapeId: this.currentLandscape.id,
          objectId: o.id,
          props: objectUpdate,
          versionId: this.currentVersion.id
        }))

        tasks.push({
          id: object.id,
          props: objectUpdate,
          type: 'model-object-update'
        })
      })

      const currentDiagramContent = this.currentDiagramContent
      if (currentDiagramContent) {
        const {
          diagramProps,
          revertDiagramProps
        } = this.updateDiagramContent(modelObjects)

        const { diagramContent, diagramContentUpdate } = this.diagramModule.generateDiagramContentCommit(currentDiagramContent.id, diagramProps)
        this.diagramModule.setDiagramContentVersion(diagramContent)
        this.editorModule.addToTaskQueue({
          func: () => this.diagramModule.diagramContentUpdate({
            diagramId: currentDiagramContent.id,
            landscapeId: this.currentLandscapeId,
            props: diagramContentUpdate,
            versionId: this.currentVersionId
          })
        })

        revertTasks.push({
          id: currentDiagramContent.id,
          props: revertDiagramProps,
          type: 'diagram-content-update'
        })
        tasks.push({
          id: currentDiagramContent.id,
          props: diagramProps,
          type: 'diagram-content-update'
        })
      }
    }

    this.editorModule.addToTaskQueue({ func })

    this.editorModule.addTaskList({
      revertTasks: [{
        tasks: revertTasks,
        type: 'batch'
      }],
      tasks: [{
        tasks,
        type: 'batch'
      }]
    })
  }
}
