
import { FormController, validationRules } from '@icepanel/app-form'
import { ModelConnection, Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { DiagramModule } from '@/modules/diagram/store'
import { EditorModule } from '@/modules/editor/store'
import { ModelModule } from '@/modules/model/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

@Component({
  name: 'ConnectionNameEdit'
})
export default class extends Vue {
  editorModule = getModule(EditorModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)

  @Prop() readonly connection!: ModelConnection

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  formController = new FormController({
    initialModel: {
      name: ''
    },
    validationRules: {
      name: validationRules.exists
    }
  })

  created () {
    this.formController.submitHandler = async model => {
      if (this.connection.name === model.name) {
        return
      }

      this.updateConnectionName(this.connection, model.name)
    }
    this.formController.on('success', () => {
      this.$emit('success')
    })

    this.formController.setInitialModel({
      name: this.connection.name
    })
    this.formController.resetModel()
  }

  updateConnectionName (con: ModelConnection, name: string) {
    const tasks: Task[] = []
    const revertTasks: Task[] = []

    if (this.currentDiagram?.status === 'draft') {
      revertTasks.push({
        id: this.currentDiagram.id,
        props: {
          tasksProposed: {
            $append: [{
              id: con.id,
              props: {
                name: con.name
              },
              type: 'model-connection-update'
            }]
          }
        },
        type: 'diagram-content-update'
      }, {
        route: this.$route,
        type: 'navigation'
      })

      const { diagramContent, diagramContentUpdate } = this.diagramModule.generateDiagramContentCommit(this.currentDiagram.id, {
        tasksProposed: {
          $append: [{
            id: con.id,
            props: {
              name
            },
            type: 'model-connection-update'
          }]
        }
      })
      this.diagramModule.setDiagramContentVersion(diagramContent)
      this.editorModule.addToTaskQueue({
        func: () => this.diagramModule.diagramContentUpdate({
          diagramId: diagramContent.id,
          landscapeId: this.currentLandscapeId,
          props: diagramContentUpdate,
          versionId: this.currentVersion.id
        })
      })

      tasks.push({
        id: diagramContent.id,
        props: diagramContentUpdate,
        type: 'diagram-content-update'
      })
    } else {
      revertTasks.push({
        id: con.id,
        props: {
          name: con.name
        },
        type: 'model-connection-update'
      }, {
        route: this.$route,
        type: 'navigation'
      })

      const { connection, connectionUpdate } = this.modelModule.generateConnectionCommit(con.id, {
        name
      })
      this.modelModule.setConnectionVersion(connection)
      this.editorModule.addToTaskQueue({
        func: () => this.modelModule.connectionUpdate({
          connectionId: connection.id,
          landscapeId: this.currentLandscapeId,
          props: connectionUpdate,
          versionId: this.currentVersion.id
        })
      })

      tasks.push({
        id: connection.id,
        props: connectionUpdate,
        type: 'model-connection-update'
      }, {
        route: this.$route,
        type: 'navigation'
      })

      this.editorModule.addTaskList({
        revertTasks,
        tasks
      })
    }
  }
}
