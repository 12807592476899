
import { Context } from '@icepanel/app-canvas'
import { angleBetweenPoints, bestLineBetweenConnectorRects, connectionCurvePoints, connectorRectPoints, IPoint, layoutAreaObjects, movePointInDirection, objectConnectionPoints, rectIntersects, rectPadded, snapToGrid } from '@icepanel/app-graphics'
import { DiagramContentPartial, DiagramObject, DiagramType, ModelConnectionDirection, ModelObjectType, PermissionType, Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import randomId from '@/helpers/random-id'
import { iconUrlForTheme } from '@/helpers/theme'
import { DiagramModule } from '@/modules/diagram/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import { TeamModule } from '@/modules/team/store'
import { VersionModule } from '@/modules/version/store'

import InDiagramsMenu from '../in-diagrams-menu.vue'
import ObjectFamilyMenu from '../objects/object-family-menu.vue'
import NameEdit from './name-edit.vue'

const MODEL_LEVEL_OBJECTS: Record<DiagramType, ModelObjectType[]> = {
  'app-diagram': ['system', 'actor', 'group', 'app', 'store'],
  'component-diagram': ['system', 'actor', 'group', 'app', 'store', 'component'],
  'context-diagram': ['system', 'actor', 'group']
}

@Component({
  components: {
    InDiagramsMenu,
    NameEdit,
    ObjectFamilyMenu
  },
  name: 'ModelObjectConnectionsListItem'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  teamModule = getModule(TeamModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly connectionId!: string
  @Prop() readonly originId!: string
  @Prop() readonly targetId!: string
  @Prop() readonly permission!: PermissionType

  editing = false
  iconUrlForTheme = iconUrlForTheme

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentLandscape?.organizationId)
  }

  get shareLinkPreventNavigation () {
    return !!this.shareModule.shareLinkOptions?.preventNavigation && this.shareModule.shareLinkOptions.shareLinkId === this.currentShareLink?.id
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get currentDiagramContent () {
    return Object.values(this.diagramModule.diagramContents).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get diagramConnection () {
    return this.currentDiagramContent ? Object.values(this.currentDiagramContent.connections).find(o => o.originId === this.diagramOriginObject?.id && o.targetId === this.diagramTargetObject?.id && o.modelId === this.connection.id) : undefined
  }

  get diagramOriginObject () {
    return this.currentDiagramContent ? Object.values(this.currentDiagramContent.objects).find(o => o.modelId === this.originId) : undefined
  }

  get diagramTargetObject () {
    return this.currentDiagramContent ? Object.values(this.currentDiagramContent.objects).find(o => o.modelId === this.targetId) : undefined
  }

  get viewingDiagram () {
    return !!this.$routeName?.endsWith('-diagram')
  }

  get objectTypesAllowed () {
    return !!this.currentDiagram && !!this.origin && !!this.target && MODEL_LEVEL_OBJECTS[this.currentDiagram.type].includes(this.origin.type) && MODEL_LEVEL_OBJECTS[this.currentDiagram.type].includes(this.target.type)
  }

  get connectionExistsInDiagram () {
    return !!this.currentDiagramContent && !!this.origin && !!this.target && Object.values(this.currentDiagramContent.connections).some(o => o.modelId === this.connection.id && o.id !== this.diagramConnection?.id)
  }

  get connection () {
    return this.modelModule.connections[this.connectionId]
  }

  get connectionDiagramIds () {
    return Object.keys(this.connection.diagrams)
  }

  get connectionOrigin () {
    return this.connection.originId ? this.modelModule.objects[this.connection.originId] : undefined
  }

  get connectionTarget () {
    return this.connection.targetId ? this.modelModule.objects[this.connection.targetId] : undefined
  }

  get origin () {
    return this.modelModule.objects[this.originId]
  }

  get target () {
    return this.modelModule.objects[this.targetId]
  }

  get originFamilyIds () {
    return [this.connection.originId, ...this.modelModule.objects[this.connection.originId]?.parentIds || []]
  }

  get targetFamilyIds () {
    return [this.connection.targetId, ...this.modelModule.objects[this.connection.targetId]?.parentIds || []]
  }

  get lower () {
    if ((!this.connection.direction || this.connection.direction === 'bidirectional') && ((this.connection.originId === this.originId && this.connection.targetId === this.targetId) || (this.connection.originId === this.targetId && this.connection.targetId === this.originId))) {
      return false
    } else if (this.connection.direction === 'outgoing' && this.connection.originId === this.originId && this.connection.targetId === this.targetId) {
      return false
    } else if ((!this.connection.direction || this.connection.direction === 'bidirectional') && ((this.originFamilyIds.includes(this.originId) && this.targetFamilyIds.includes(this.targetId)) || (this.originFamilyIds.includes(this.targetId) && this.targetFamilyIds.includes(this.originId)))) {
      return true
    } else if (this.connection.direction === 'outgoing' && this.originFamilyIds.includes(this.originId) && this.targetFamilyIds.includes(this.targetId)) {
      return true
    } else {
      return false
    }
  }

  get deleteEnabled () {
    if (this.currentDiagram?.status === 'draft' && this.currentDiagramContent) {
      return this.currentDiagramContent.tasksProposed
        .map(o => o.task.type === 'model-connection-create' ? o.task.id : undefined)
        .filter((o): o is string => !!o)
        .includes(this.connectionId)
    } else {
      return true
    }
  }

  get directionIcons () {
    return (direction: ModelConnectionDirection) => {
      switch (direction) {
        case 'outgoing': return '$fas-long-arrow-alt-right'
        case 'bidirectional': return '$fas-arrows-alt-h'
        default: return '$custom-solid-horizontal-rule'
      }
    }
  }

  isModelObjectProtected (modelObjectId: string) {
    if (this.permission === 'admin') { return false }
    if (!modelObjectId) { return false }
    const modelObject = this.modelModule.objects[modelObjectId]
    return (
      modelObject &&
      modelObject.teamOnlyEditing &&
      !!modelObject.teamIds.length &&
      !this.teamModule.userTeams.some(o => modelObject.teamIds.includes(o.id))
    )
  }

  addConnection () {
    const currentDiagramContent = this.currentDiagramContent
    if (!currentDiagramContent) {
      return
    }

    const diagramObject = this.diagramOriginObject || this.diagramTargetObject
    if (!diagramObject) {
      throw new Error('Diagram object not found')
    }

    const diagramProps: DiagramContentPartial = {}
    const revertDiagramProps: DiagramContentPartial = {}

    const connections = { ...currentDiagramContent.connections }
    const objects = { ...currentDiagramContent.objects }

    let angle = Math.floor(Math.random() * 359)

    const diagramModelObjectFamilyIds = [currentDiagramContent.modelId, ...this.modelModule.objects[currentDiagramContent.modelId]?.parentIds || []]

    let origin: DiagramObject
    if (this.diagramOriginObject) {
      origin = this.diagramOriginObject
    } else {
      const originModel = this.lower ? this.origin : this.connectionOrigin
      if (!originModel || originModel.type === 'root') {
        throw new Error('Could not find origin model')
      }

      const id = randomId()
      const size = Context.Diagram.Obj.getDefaultSize(originModel.type)

      const originModelParentIds = this.modelModule.objects[originModel.id]?.parentIds || []
      const originModelParents = originModelParentIds.map(o => this.modelModule.objects[o])
      const originModelFamily = [originModel.id, ...originModelParentIds].map(o => this.modelModule.objects[o])
      const originModelFamilyIds = originModelFamily.map(o => o.id)
      const originFamilyObject = Object.values(objects).find(o => originModelFamilyIds.includes(o.modelId))

      if (originFamilyObject) {
        angle = angleBetweenPoints(diagramObject, originFamilyObject)
      }

      let point: IPoint = { x: diagramObject.x, y: diagramObject.y }
      point = movePointInDirection(point, angle, 32)
      while (Object.values(objects).some(o => (diagramModelObjectFamilyIds.includes(o.modelId) || o.modelId === diagramObject.modelId || o.shape !== 'area') && rectIntersects(rectPadded({ ...point, ...size }, { bottom: 64, left: 64, right: 64, top: 64 }), o))) {
        point = movePointInDirection(point, angle, 32)
      }
      point = movePointInDirection(point, angle, 32)

      originModelParents
        .filter(o => o.type !== 'root' && !diagramModelObjectFamilyIds.slice(1).includes(o.id))
        .forEach(o => {
          const existingObject = Object.values(objects).find(m => m.modelId === o.id)
          if (existingObject) {
            if (existingObject.shape !== 'area') {
              revertDiagramProps.objects = {
                ...revertDiagramProps.objects,
                $update: {
                  ...revertDiagramProps.objects?.$update,
                  [existingObject.id]: {
                    ...revertDiagramProps.objects?.$update?.[existingObject.id],
                    type: existingObject.type
                  }
                }
              }

              objects[existingObject.id] = {
                ...existingObject,
                shape: 'area'
              }

              diagramProps.objects = {
                ...diagramProps.objects,
                $update: {
                  ...diagramProps.objects?.$update,
                  [existingObject.id]: {
                    ...diagramProps.objects?.$update?.[existingObject.id],
                    shape: 'area'
                  }
                }
              }
            }
          } else if (o.type !== 'root') {
            const areaId = randomId()

            revertDiagramProps.objects = {
              ...revertDiagramProps.objects,
              $remove: [
                ...revertDiagramProps.objects?.$remove || [],
                areaId
              ]
            }

            objects[areaId] = {
              height: 0,
              id: areaId,
              modelId: o.id,
              shape: 'area',
              type: o.type,
              width: 0,
              x: 0,
              y: 0
            }

            diagramProps.objects = {
              ...diagramProps.objects,
              $add: {
                ...diagramProps.objects?.$add,
                [areaId]: objects[areaId]
              }
            }
          }
        })

      revertDiagramProps.objects = {
        ...revertDiagramProps.objects,
        $remove: [
          ...revertDiagramProps.objects?.$remove || [],
          id
        ]
      }

      origin = {
        id,
        modelId: originModel.id,
        shape: originModel.type === 'group' ? 'area' : 'box',
        type: originModel.type,
        x: snapToGrid(point.x),
        y: snapToGrid(point.y),
        ...size
      }
      objects[id] = origin

      diagramProps.objects = {
        ...diagramProps.objects,
        $add: {
          ...diagramProps.objects?.$add,
          [id]: origin
        }
      }
    }

    let target: DiagramObject
    if (this.diagramTargetObject) {
      target = this.diagramTargetObject
    } else {
      const targetModel = this.lower ? this.target : this.connectionTarget
      if (!targetModel || targetModel.type === 'root') {
        throw new Error('Could not find target model')
      }

      const id = randomId()
      const size = Context.Diagram.Obj.getDefaultSize(targetModel.type)

      const targetModelParentIds = this.modelModule.objects[targetModel.id]?.parentIds || []
      const targetModelParents = targetModelParentIds.map(o => this.modelModule.objects[o])
      const targetModelFamily = [targetModel.id, ...targetModelParentIds].map(o => this.modelModule.objects[o])
      const targetModelFamilyIds = targetModelFamily.map(o => o.id)
      const targetFamilyObject = Object.values(objects).find(o => targetModelFamilyIds.includes(o.modelId))

      if (targetFamilyObject) {
        angle = angleBetweenPoints(diagramObject, targetFamilyObject)
      }

      let point: IPoint = { x: diagramObject.x, y: diagramObject.y }
      point = movePointInDirection(point, angle, 32)
      while (Object.values(objects).some(o => (diagramModelObjectFamilyIds.includes(o.modelId) || o.modelId === diagramObject.modelId || o.shape !== 'area') && rectIntersects(rectPadded({ ...point, ...size }, { bottom: 64, left: 64, right: 64, top: 64 }), o))) {
        point = movePointInDirection(point, angle, 32)
      }
      point = movePointInDirection(point, angle, 32)

      targetModelParents
        .filter(o => o.type !== 'root' && !diagramModelObjectFamilyIds.slice(1).includes(o.id))
        .forEach(o => {
          const existingObject = Object.values(objects).find(m => m.modelId === o.id)
          if (existingObject) {
            if (existingObject.shape !== 'area') {
              revertDiagramProps.objects = {
                ...revertDiagramProps.objects,
                $update: {
                  ...revertDiagramProps.objects?.$update,
                  [existingObject.id]: {
                    ...revertDiagramProps.objects?.$update?.[existingObject.id],
                    type: existingObject.type
                  }
                }
              }

              objects[existingObject.id] = {
                ...existingObject,
                shape: 'area'
              }

              diagramProps.objects = {
                ...diagramProps.objects,
                $update: {
                  ...diagramProps.objects?.$update,
                  [existingObject.id]: {
                    ...diagramProps.objects?.$update?.[existingObject.id],
                    shape: 'area'
                  }
                }
              }
            }
          } else if (o.type !== 'root') {
            const areaId = randomId()

            revertDiagramProps.objects = {
              ...revertDiagramProps.objects,
              $remove: [
                ...revertDiagramProps.objects?.$remove || [],
                areaId
              ]
            }

            objects[areaId] = {
              height: 0,
              id: areaId,
              modelId: o.id,
              shape: 'area',
              type: o.type,
              width: 0,
              x: 0,
              y: 0
            }

            diagramProps.objects = {
              ...diagramProps.objects,
              $add: {
                ...diagramProps.objects?.$add,
                [areaId]: objects[areaId]
              }
            }
          }
        })

      revertDiagramProps.objects = {
        ...revertDiagramProps.objects,
        $remove: [
          ...revertDiagramProps.objects?.$remove || [],
          id
        ]
      }

      target = {
        id,
        modelId: targetModel.id,
        shape: targetModel.type === 'group' ? 'area' : 'box',
        type: targetModel.type,
        x: snapToGrid(point.x),
        y: snapToGrid(point.y),
        ...size
      }
      objects[id] = target

      diagramProps.objects = {
        ...diagramProps.objects,
        $add: {
          ...diagramProps.objects?.$add,
          [id]: target
        }
      }
    }

    Object
      .entries(layoutAreaObjects(objects, this.modelModule.objects))
      .forEach(([id, rect]) => {
        revertDiagramProps.objects = {
          ...revertDiagramProps.objects,
          $update: {
            ...revertDiagramProps.objects?.$update,
            [id]: {
              ...revertDiagramProps.objects?.$update?.[id],
              height: objects[id].height,
              width: objects[id].width,
              x: objects[id].x,
              y: objects[id].y
            }
          }
        }
        objects[id] = {
          ...objects[id],
          ...rect
        }
        diagramProps.objects = {
          ...diagramProps.objects,
          $update: {
            ...diagramProps.objects?.$update,
            [id]: {
              ...diagramProps.objects?.$update?.[id],
              ...rect
            }
          }
        }
      })
    Object.entries(objectConnectionPoints(Object.values(objects), Object.values(connections))).forEach(([id, points]) => {
      revertDiagramProps.connections = {
        ...revertDiagramProps.connections,
        $update: {
          ...revertDiagramProps.connections?.$update,
          [id]: {
            ...revertDiagramProps.connections?.$update?.[id],
            points: connections[id].points
          }
        }
      }
      connections[id] = {
        ...connections[id],
        points
      }
      diagramProps.connections = {
        ...diagramProps.connections,
        $update: {
          ...diagramProps.connections?.$update,
          [id]: {
            ...diagramProps.connections?.$update?.[id],
            points
          }
        }
      }
    })

    const { originConnector, targetConnector } = bestLineBetweenConnectorRects(origin, target, 'curved')
    const originPoint = connectorRectPoints(origin)[originConnector]
    const targetPoint = connectorRectPoints(target)[targetConnector]
    if (originPoint && targetPoint) {
      const id = randomId()

      revertDiagramProps.connections = {
        ...revertDiagramProps.connections,
        $remove: [
          ...revertDiagramProps.connections?.$remove || [],
          id
        ]
      }

      connections[id] = {
        id,
        labelPosition: 50,
        lineShape: this.currentOrganization?.lineShapeDefault ?? 'curved',
        modelId: this.connection.id,
        originConnector,
        originId: origin.id,
        points: connectionCurvePoints(originPoint, originConnector, targetPoint, targetConnector),
        targetConnector,
        targetId: target.id
      }

      diagramProps.connections = {
        ...diagramProps.connections,
        $add: {
          ...diagramProps.connections?.$add,
          [id]: connections[id]
        }
      }
    }

    const revertTasks: Task[] = [{
      id: currentDiagramContent.id,
      props: revertDiagramProps,
      type: 'diagram-content-update'
    }, {
      route: this.$route,
      type: 'navigation'
    }]

    const { diagramContent, diagramContentUpdate, modelConnectionDiagramAdd, modelConnectionDiagramRemove } = this.diagramModule.generateDiagramContentCommit(currentDiagramContent.id, diagramProps)
    this.diagramModule.setDiagramContentVersion(diagramContent)
    this.modelModule.setConnectionDiagrams({ modelConnectionDiagramAdd, modelConnectionDiagramRemove })
    this.editorModule.addToTaskQueue({
      func: () => this.diagramModule.diagramContentUpdate({
        diagramId: diagramContent.id,
        landscapeId: this.currentLandscape.id,
        props: diagramContentUpdate,
        versionId: this.currentVersion.id
      })
    })

    this.editorModule.addTaskList({
      revertTasks,
      tasks: [{
        id: diagramContent.id,
        props: diagramContentUpdate,
        type: 'diagram-content-update'
      }, {
        route: this.$route,
        type: 'navigation'
      }]
    })
  }

  removeConnection () {
    const currentDiagram = this.currentDiagram
    if (!currentDiagram) {
      return
    }
    if (!this.diagramConnection) {
      throw new Error('Diagram connection not found')
    }

    const revertTasks: Task[] = [{
      id: currentDiagram.id,
      props: {
        connections: {
          $add: {
            [this.diagramConnection.id]: this.diagramConnection
          }
        }
      },
      type: 'diagram-content-update'
    }, {
      route: this.$route,
      type: 'navigation'
    }]

    const { diagramContent, diagramContentUpdate, modelConnectionDiagramAdd, modelConnectionDiagramRemove } = this.diagramModule.generateDiagramContentCommit(currentDiagram.id, {
      connections: {
        $remove: [this.diagramConnection.id]
      }
    })
    this.diagramModule.setDiagramContentVersion(diagramContent)
    this.modelModule.setConnectionDiagrams({ modelConnectionDiagramAdd, modelConnectionDiagramRemove })
    this.editorModule.addToTaskQueue({
      func: () => this.diagramModule.diagramContentUpdate({
        diagramId: currentDiagram.id,
        landscapeId: this.currentLandscape.id,
        props: diagramContentUpdate,
        versionId: this.currentVersion.id
      })
    })

    this.editorModule.addTaskList({
      revertTasks,
      tasks: [{
        id: diagramContent.id,
        props: diagramContentUpdate,
        type: 'diagram-content-update'
      }, {
        route: this.$route,
        type: 'navigation'
      }]
    })
  }

  startEditing () {
    if (this.permission !== 'read') {
      this.editing = true
    }
  }
}
