
import { ModelObject, PermissionType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { AlertModule } from '@/modules/alert/store'
import { DiagramModule } from '@/modules/diagram/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'
import { directIncomingConnections, directOutgoingConnections, lowerIncomingConnections, lowerOutgoingConnections } from '../../helpers/connections'
import Item from '../object-connections-list/item.vue'
import EmptyItem from './empty-item.vue'
import Header from './header.vue'

interface ListItemHeader {
  directConnectionCount: number
  id: string
  lowerConnectionCount: number
  originId: string
  targetId: string
  type: 'header'
}

interface ListItemConnection {
  connectionId: string
  id: string
  originId: string
  targetId: string
  type: 'connection'
}

interface ListEmptyItem {
  id: string
  title: string
  type: 'empty'
}

type ListItem = ListItemHeader | ListItemConnection | ListEmptyItem

@Component({
  components: {
    EmptyItem,
    Header,
    Item
  },
  name: 'ModelObjectDependenciesList'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly object!: ModelObject
  @Prop() readonly permission!: PermissionType

  @Ref() readonly virtualScrollRef!: { $el: HTMLElement, onScroll: () => void }

  showDirect: number[] = [0, 1]
  showLower: number[] = [0, 1]
  itemHeight = 54
  searchModel = ''
  sortedConnectionIndex: Record<string, number> = {}
  sortedExpandedConnection: string | null = null

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.verisonId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get expandedConnection () {
    return this.$queryValue('expanded_connection')
  }

  get expandedConnectionTab () {
    return this.$queryValue('expanded_connection_tab')
  }

  get objectFamilyIds () {
    const objectFamilyIds: Record<string, string[]> = {}
    Object.values(this.modelModule.connections).forEach(o => {
      objectFamilyIds[o.originId] = [o.originId, ...this.modelModule.objects[o.originId]?.parentIds ?? []].filter(o => this.modelModule.objects[o]?.type !== 'root')
      objectFamilyIds[o.targetId] = [o.targetId, ...this.modelModule.objects[o.targetId]?.parentIds ?? []].filter(o => this.modelModule.objects[o]?.type !== 'root')
    })
    return objectFamilyIds
  }

  get directOutgoingConnections () {
    return directOutgoingConnections(this.object.id, this.modelModule.connections)
  }

  get directIncomingConnections () {
    return directIncomingConnections(this.object.id, this.modelModule.connections)
  }

  get lowerOutgoingConnections () {
    return lowerOutgoingConnections(this.object, this.objectFamilyIds, this.modelModule.connections)
  }

  get lowerIncomingConnections () {
    return lowerIncomingConnections(this.object, this.objectFamilyIds, this.modelModule.connections)
  }

  get items (): ListItem[] {
    const search = this.searchModel.toLowerCase()
    const directOutgoingConnections = this.directOutgoingConnections
    const directIncomingConnections = this.directIncomingConnections
    const lowerOutgoingConnections = this.lowerOutgoingConnections
    const lowerIncomingConnections = this.lowerIncomingConnections

    const searchFilter = (origin: ModelObject, target: ModelObject, search: string, c: string) => {
      const connection = this.modelModule.connections[c]

      return connection.name.toLowerCase().includes(search) ||
        this.modelModule.objects[connection.originId]?.name.toLowerCase().includes(search) ||
        this.modelModule.objects[connection.targetId]?.name.toLowerCase().includes(search) ||
        origin.name.toLowerCase().includes(search) ||
        target.name.toLowerCase().includes(search)
    }

    const connectionKeys = new Set<string>()
    if (this.showDirect.includes(0)) {
      Object.keys(directOutgoingConnections).forEach(o => connectionKeys.add(o))
    }
    if (this.showDirect.includes(1)) {
      Object.keys(directIncomingConnections).forEach(o => connectionKeys.add(o))
    }
    if (this.showLower.includes(0)) {
      Object.keys(lowerOutgoingConnections).forEach(o => connectionKeys.add(o))
    }
    if (this.showLower.includes(1)) {
      Object.keys(lowerIncomingConnections).forEach(o => connectionKeys.add(o))
    }

    const list: ListItem[] = []

    const sort = (a: string, b: string) => {
      const connectionA = this.modelModule.connections[a]
      const connectionB = this.modelModule.connections[b]

      const connectionADiagramCount = Object.keys(connectionA.diagrams).length
      const connectionBDiagramCount = Object.keys(connectionB.diagrams).length

      if (connectionADiagramCount === connectionBDiagramCount) {
        return connectionA.name.localeCompare(connectionB.name)
      } else {
        return connectionADiagramCount - connectionBDiagramCount
      }
    }

    const memorySort = (a: string, b: string) => {
      return this.sortedConnectionIndex[a] - this.sortedConnectionIndex[b]
    }

    [...connectionKeys]
      .map(o => {
        const [originId, targetId] = o.split('-')
        const origin = this.modelModule.objects[originId]
        const target = this.modelModule.objects[targetId]

        let outgoingDirectConnections = [...(this.showDirect.includes(0) ? directOutgoingConnections[o] || [] : [])]
        let incomingDirectConnections = [...(this.showDirect.includes(1) ? directIncomingConnections[o] || [] : [])]
        let outgoingLowerConnections = [...(this.showLower.includes(0) ? lowerOutgoingConnections[o] || [] : [])]
        let incomingLowerConnections = [...(this.showLower.includes(1) ? lowerIncomingConnections[o] || [] : [])]

        if (search) {
          outgoingDirectConnections = outgoingDirectConnections.filter(c => searchFilter(origin, target, search, c))
          incomingDirectConnections = incomingDirectConnections.filter(c => searchFilter(origin, target, search, c))
          outgoingLowerConnections = outgoingLowerConnections.filter(c => searchFilter(origin, target, search, c))
          incomingLowerConnections = incomingLowerConnections.filter(c => searchFilter(origin, target, search, c))
        }

        return {
          directConnectionCount: incomingDirectConnections.length + outgoingDirectConnections.length,
          incomingDirectConnections,
          incomingLowerConnections,
          key: o,
          lowerConnectionCount: incomingLowerConnections.length + outgoingLowerConnections.length,
          origin,
          outgoingDirectConnections,
          outgoingLowerConnections,
          target
        }
      })
      .filter(o => o.origin && o.target && (o.directConnectionCount || o.lowerConnectionCount))
      .sort((a, b) => {
        if (a.directConnectionCount !== b.directConnectionCount) {
          return a.directConnectionCount > b.directConnectionCount ? -1 : 1
        }
        if (a.lowerConnectionCount !== b.lowerConnectionCount) {
          return a.lowerConnectionCount > b.lowerConnectionCount ? -1 : 1
        }
        return 0
      })
      .forEach(o => {
        if (this.expandedConnection === 'all' || (this.expandedConnection === o.key && this.sortedExpandedConnection !== o.key)) {
          o.outgoingDirectConnections.sort(sort)
          o.outgoingDirectConnections.forEach((o, index) => { this.sortedConnectionIndex[o] = index })

          o.incomingDirectConnections.sort(sort)
          o.incomingDirectConnections.forEach((o, index) => { this.sortedConnectionIndex[o] = index })

          o.outgoingLowerConnections.sort(sort)
          o.outgoingLowerConnections.forEach((o, index) => { this.sortedConnectionIndex[o] = index })

          o.incomingLowerConnections.sort(sort)
          o.incomingLowerConnections.forEach((o, index) => { this.sortedConnectionIndex[o] = index })

          this.sortedExpandedConnection = o.key
        } else {
          o.outgoingDirectConnections.sort(memorySort)
          o.incomingDirectConnections.sort(memorySort)
          o.outgoingLowerConnections.sort(memorySort)
          o.incomingLowerConnections.sort(memorySort)
        }

        list.push({
          directConnectionCount: o.directConnectionCount,
          id: o.key,
          lowerConnectionCount: o.lowerConnectionCount,
          originId: o.origin.id,
          targetId: o.target.id,
          type: 'header'
        })

        if (o.key === this.expandedConnection || this.expandedConnection === 'all') {
          if (this.expandedConnectionTab === 'direct' || this.expandedConnection === 'all') {
            if (o.directConnectionCount) {
              o.outgoingDirectConnections.forEach(d => {
                const connection = this.modelModule.connections[d]
                list.push({
                  connectionId: d,
                  id: `${o.key}-${connection.id}-${connection.originId}-${connection.targetId}`,
                  originId: connection.originId,
                  targetId: connection.targetId,
                  type: 'connection'
                })
              })
              o.incomingDirectConnections.forEach(d => {
                const connection = this.modelModule.connections[d]
                list.push({
                  connectionId: d,
                  id: `${o.key}-${connection.id}-${connection.originId}-${connection.targetId}`,
                  originId: connection.originId,
                  targetId: connection.targetId,
                  type: 'connection'
                })
              })
            } else if (this.expandedConnection !== 'all') {
              list.push({
                id: `${o.key}-empty`,
                title: 'No direct connections',
                type: 'empty'
              })
            }
          }
          if (this.expandedConnectionTab === 'lower' || this.expandedConnection === 'all') {
            if (o.lowerConnectionCount) {
              o.outgoingLowerConnections.forEach(d => {
                const connection = this.modelModule.connections[d]
                list.push({
                  connectionId: d,
                  id: `${o.key}-${connection.id}-${connection.originId}-${connection.targetId}`,
                  originId: o.origin.id,
                  targetId: o.target.id,
                  type: 'connection'
                })
              })
              o.incomingLowerConnections.forEach(d => {
                const connection = this.modelModule.connections[d]
                list.push({
                  connectionId: d,
                  id: `${o.key}-${connection.id}-${connection.originId}-${connection.targetId}`,
                  originId: o.origin.id,
                  targetId: o.target.id,
                  type: 'connection'
                })
              })
            } else if (this.expandedConnection !== 'all') {
              list.push({
                id: `${o.key}-empty`,
                title: 'No lower connections',
                type: 'empty'
              })
            }
          }
        }
      })

    return list
  }

  @Watch('object')
  onObjectChanged (object: ModelObject, prevObject: ModelObject) {
    this.virtualScrollRef.$el.scrollTop = 0

    if (this.currentLandscape && object.id !== prevObject.id) {
      analytics.modelDependenciesScreen.track(this, {
        landscapeId: [this.currentLandscape.id],
        modelObjectType: object.type,
        organizationId: [this.currentLandscape.organizationId]
      })
    }
  }

  @Watch('searchModel')
  onSearchModelChanged () {
    this.virtualScrollRef.$el.scrollTop = 0
    this.$replaceQuery({ expanded_connection: this.searchModel.length ? 'all' : undefined })
  }

  @Watch('items')
  onItemsChanged (items: ListItem[], prevItems: ListItem[]) {
    if (items.length !== prevItems.length) {
      const index = this.virtualScrollRef.$el.scrollTop / this.itemHeight
      const topItem = prevItems[index]
      if (topItem) {
        const newItemIndex = items.findIndex(o => o.id === topItem.id)
        if (newItemIndex > -1) {
          this.virtualScrollRef.$el.scrollTop = newItemIndex * this.itemHeight
        }
      }

      this.$nextTick(() => this.virtualScrollRef?.onScroll())
    }
  }

  mounted () {
    if (this.currentLandscape) {
      analytics.modelDependenciesScreen.track(this, {
        landscapeId: [this.currentLandscape.id],
        modelObjectType: this.object.type,
        organizationId: [this.currentLandscape.organizationId]
      })
    }
  }

  toggleConnectionExpansion (connectionId: string, directConnectionCount: number, lowerConnectionCount: number) {
    let expandedTab = 'direct'
    if (directConnectionCount === 0 && lowerConnectionCount > 0) {
      expandedTab = 'lower'
    }

    this.$replaceQuery({
      expanded_connection: this.expandedConnection === connectionId ? null : connectionId,
      expanded_connection_tab: expandedTab
    })
  }
}
